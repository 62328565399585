import React, { useContext, useState } from 'react';
import Brand from '../../../layout/Brand/Brand';
import Navigation, {
	NavigationLine,
} from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {
	pagesEdu,
	estudiantesMenu,
	dashboardPagos,
	catalogos,
  depositos,
} from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Aside, {
	AsideBody,
	AsideFoot,
	AsideHead,
} from '../../../layout/Aside/Aside';
import AuthContext from '../../../contexts/authContext';
import { Navigate } from 'react-router-dom';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { userData } = useContext(AuthContext);
	const storedUserData = JSON.parse(localStorage.getItem("userData") || "{}");

	const userRole = userData;

	if (!storedUserData) {
		return <Navigate to='/login' />;
	}

	const hasPermission = (
		userData: any | undefined,
		resource: string | undefined
	) => {
		if (!userData || !userData.role) return false; // Si no hay usuario o rol, no tiene permiso

		if (userData.role === 'ADMIN') {
			// El administrador puede ver recursos de ADMIN y USER
			return resource === 'ADMIN' || resource === 'USER';
		}
		if (resource === 'USER') return userData.role === 'USER'; // Solo los usuarios normales pueden ver recursos de USER

		return false; // Por defecto, no permitir acceso si no se especifica el recurso o si no cumple ninguna condición
	};

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false
	);

	const filterMenu = (menu: any) => {
		return Object.entries(menu).reduce((acc, [key, item]) => {
			// @ts-ignore
			if (hasPermission(userRole, item.resource)) {
				acc[key] = item;
			}
			return acc;
		}, {} as any);
	};

	const estudiantesIndex = filterMenu(pagesEdu);
	const dashboardPagosIndex = filterMenu(dashboardPagos);
	const catalogosIndex = filterMenu(catalogos);
	const estudiantesMenuIndex = filterMenu(estudiantesMenu);
  const depositosIndex = filterMenu(depositos)

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{/* <Navigation menu={dashboardPagesMenu} id='aside-dashboards' /> */}
				<NavigationLine />
				{!doc && (
					<>
						<Navigation menu={dashboardPagosIndex} id='dashboard-pagos' />
						<Navigation menu={estudiantesIndex} id='aside-menu-edu' />
						<NavigationLine />
						<Navigation
							menu={estudiantesMenuIndex}
							id='aside-menu-estudiantes'
						/>
						<NavigationLine />
						<Navigation menu={catalogosIndex} id='aside-menu-catalogos' />
						<NavigationLine />
            <Navigation menu={depositosIndex} id="aside-menu-depositos" />
						{storedUserData.role === 'ADMIN' && (
							<NavigationLine />
						)}
						{/* <Navigation menu={demoPagesMenu} id='aside-demo-pages' />
						<NavigationLine />
						<Navigation menu={pageLayoutTypesPagesMenu} id='aside-menu' />
						<NavigationLine /> */}
						{/* <nav>
							<div className='navigation'>
								<div className='navigation-item'>
									<span className='navigation-link navigation-link-pill'>
										<span className='navigation-link-info'>
											<span className='navigation-text'>
												<Popovers
													title='Aside.tsx'
													desc={
														<code>
															src/pages/_layout/_asides/DefaultAside.tsx
														</code>
													}>
													Aside
												</Popovers>
												<code className='ps-3'>DefaultAside.tsx</code>
											</span>
										</span>
									</span>
								</div>
							</div>
						</nav> */}
					</>
				)}
				{asideStatus && doc && <div className='p-4'>Documentation</div>}
			</AsideBody>
			<AsideFoot>
				<nav aria-label='aside-bottom-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								localStorage.setItem('facit_asideDocStatus', String(!doc));
								setDoc(!doc);
							}}
							data-tour='documentation'>
							{/* <span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={doc ? 'ToggleOn' : 'ToggleOff'}
										color={doc ? 'success' : undefined}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{t('menu:Documentation') as ReactNode}
									</span>
								</span>
								<span className='navigation-link-extra'>
									<Icon
										icon='Circle'
										className={classNames(
											'navigation-notification',
											'text-success',
											'animate__animated animate__heartBeat animate__infinite animate__slower',
										)}
									/>
								</span>
							</span> */}
						</div>
					</div>
				</nav>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
