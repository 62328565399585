export const summaryPageTopMenu = {
	intro: {
		id: 'intro',
		text: 'Intro',
		path: '#intro',
		icon: 'Vrpano',
		subMenu: null,
	},
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};

export const dashboardPagos = {
	dashboardPagos: {
		id: 'dashboards-pagos',
		text: 'Dashboard Pago Estudiantes',
		path: '/dashboards-pagos',
		icon: 'Payments',
		subMenu: null,
		resource: 'ADMIN',
	},
};
export const pagesEdu = {
	estudiantes: {
		id: 'estudiantes',
		text: 'Cobros y Pagos',
		path: 'cobros-y-pagos',
		icon: 'Payments',
		subMenu: {
			vistaGeneral: {
				id: 'estudiantes-vista-general',
				text: 'Estados de cuenta',
				path: 'cobros-y-pagos/vista-general',
				icon: 'People',
				resource: 'USER',
			},
			estudiantesPagoPendiente: {
				id: 'pendientesDePago',
				text: 'Pendientes de Pago',
				path: 'cobros-y-pagos/pendientes-de-pago',
				icon: 'PendingActions',
				notification: 'warning',
				resource: 'USER',
			},
		},
		resource: 'USER',
	},
};

export const estudiantesMenu = {
	estudiantesListado: {
		id: 'aside-menu-catalogos',
		text: 'Estudiantes',
		path: 'estudiantes',
		icon: 'People',
		subMenu: {
			listadoEstudiantesIndex: {
				id: 'estudiantesMenu',
				text: 'Listado Estudiantes',
				path: 'estudiantes/listado-estudiantes',
				icon: 'People',
				resource: 'ADMIN',
			},
			listadoInscripcionesIndex: {
				id: 'inscripcionesMenu',
				text: 'Inscripciones',
				path: '/inscripciones',
				icon: 'Insights',
				resource: 'ADMIN',
			},
		},
		resource: 'USER',
	},
};

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboards-pagos',
		text: 'Dashboard',
		path: '/dashboards-pagos',
		icon: 'Dashboard',
		subMenu: null,
		resource: 'ADMIN',
	},
};

export const demoPagesMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};

export const pageLayoutTypesPagesMenu = {
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	blank: {
		id: 'blank',
		text: 'Blank',
		path: 'page-layouts/blank',
		icon: 'check_box_outline_blank ',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			headerAndSubheader: {
				id: 'headerAndSubheader',
				text: 'Header & Subheader',
				path: 'page-layouts/header-and-subheader',
				icon: 'ViewAgenda',
			},
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
			onlySubheader: {
				id: 'onlySubheader',
				text: 'Only Subheader',
				path: 'page-layouts/only-subheader',
				icon: 'ViewStream',
			},
			onlyContent: {
				id: 'onlyContent',
				text: 'Only Content',
				path: 'page-layouts/only-content',
				icon: 'WebAsset',
			},
		},
	},
	asideTypes: {
		id: 'asideTypes',
		text: 'Aside Types',
		path: 'aside-types',
		icon: 'Vertical Split',
		subMenu: {
			defaultAside: {
				id: 'defaultAside',
				text: 'Default Aside',
				path: 'aside-types/default-aside',
				icon: 'ViewQuilt',
			},
			minimizeAside: {
				id: 'minimizeAside',
				text: 'Minimize Aside',
				path: 'aside-types/minimize-aside',
				icon: 'View Compact',
			},
		},
	},
};

export const catalogos = {
	catalogos: {
		id: 'aside-menu-catalogos',
		text: 'Catálogos',
		path: '/catalogos',
		icon: 'List',
		subMenu: {
			catalogoCobros: {
				id: 'Cobros',
				text: 'Cobros',
				path: 'catalogos/cobros',
				icon: 'Payments',
				resource: 'USER',
			},
			catalogoDescuentos: {
				id: 'Descuentos',
				text: 'Descuentos',
				path: 'catalogos/descuentos',
				icon: 'LocalOffer',
				resource: 'USER',
			},
			catalogoCiclos: {
				id: 'Ciclos',
				text: 'Ciclos',
				path: 'catalogos/ciclos',
				icon: 'IncompleteCircle',
				resource: 'USER',
			},
			catalogoGrados: {
				id: 'Grados',
				text: 'Grados',
				path: 'catalogos/grados',
				icon: 'Grade',
				resource: 'USER',
			},
			catalogoSecciones: {
				id: 'Secciones',
				text: 'Secciones',
				path: 'catalogos/secciones',
				icon: 'SafetyDivider',
				resource: 'USER',
			},
		},
		resource: 'USER',
	},
};

export const depositos = {
  depositos: {
    id: 'aside-menu-depositos',
    text: 'Depósitos',
    path: '/depositos',
    icon: 'Payments',
    resource: 'ADMIN',
  },
  resource: 'ADMIN',
};
