import React, { lazy, useContext, useEffect } from 'react';
import {
	dashboardPagesMenu,
	dashboardPagos,
	demoPagesMenu,
	pageLayoutTypesPagesMenu,
	pagesEdu,
	catalogos,
	estudiantesMenu,
  depositos,
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import AuthContext from '../contexts/authContext';
import { Navigate } from 'react-router-dom';
import LoaderThreeCircles from '../utils/Loaders/LoaderThreeCircles';

const ProtectedRoute: React.FC<{ role: string; children: React.ReactNode }> = ({
	role,
	children,
}) => {
	// @ts-ignore
	const { userData, isLoading } = useContext(AuthContext);

	// @ts-ignore
	useEffect(() => {
		if (!isLoading && !userData?.role) {
			return <Navigate to='/login' />;
		}
	}, [isLoading, userData]);

	if (isLoading) {
		return <LoaderThreeCircles />;
	}

	if (userData?.role === 'ADMIN' || userData?.role === role) {
		return <>{children}</>;
	}

	return <Navigate to='/unauthorized' />;
};

const LANDING = {
	DASHBOARD: lazy(
		() => import('../pages/presentation/dashboards/DashboardPagos')
	),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(
		() => import('../pages/presentation/page-layouts/HeaderAndSubheader')
	),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(
		() => import('../pages/presentation/page-layouts/OnlySubheader')
	),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(
		() => import('../pages/presentation/aside-types/DefaultAsidePage')
	),
	MINIMIZE_ASIDE: lazy(
		() => import('../pages/presentation/aside-types/MinimizeAsidePage')
	),
};

const DASHBOARD_PAGES = {
	DASHBOARD_PAGOS: lazy(
		() => import('../pages/presentation/dashboards/DashboardPagos')
	),
};

const USERS_PAGE = {
	ESTUDIANTES: lazy(
		() => import('../pages/estudiantes/estudiantesMenu/ListadoEstudiantesIndex')
	),
	DETALLE_ESTUDIANTE: lazy(
		() =>
			import(
				'.././components/Estudiantes/OnlyDetalleEstudiantes/DetalleEdicionEstudiante'
			)
	),
	INSCRIPCIONES: lazy(
		() =>
			import('../pages/estudiantes/estudiantesMenu/ListadoInscripcionesIndex')
	),
	CONTENIDO_ESTUDIANTES: lazy(
		() => import('../pages/estudiantes/page-layouts/EstudiantesIndex')
	),
	ESTUDIANTES_PENDIENTES_PAGO: lazy(
		() => import('../pages/estudiantes/page-layouts/EstudiantesPagosPendientes')
	),
	DETALLE_ESTUDIANTES: lazy(
		() => import('../components/Estudiantes/DetalleEstudiante')
	),
};

const CATALOGOS_PAGE = {
	CATALOGO_COBROS: lazy(
		() => import('../pages/catalogos/cobros/catalogoCobrosIndex')
	),
	CATALOGO_DESCUENTOS: lazy(
		() => import('../pages/catalogos/descuentos/catalogoDescuentosIndex')
	),
	CATALOGO_CICLOS: lazy(
		() => import('../pages/catalogos/ciclos/catalogoCiclosIndex')
	),
	CATALOGO_GRADOS: lazy(
		() => import('../pages/catalogos/grados/catalogoGradosIndex')
	),
	CATALOGO_SECCIONES: lazy(
		() => import('../pages/catalogos/secciones/catalogoSeccionesIndex')
	),
};

const DEPOSITOS_ROUTE = {
  DEPOSITOS: lazy(() => import('../pages/depositos/depositosIndex'))
};

const dashboards = [
	{
		path: dashboardPagos.dashboardPagos.path,
		element: (
			<ProtectedRoute role='ADMIN'>
				<DASHBOARD_PAGES.DASHBOARD_PAGOS />
			</ProtectedRoute>
		),
	},
];

const usuarios = [
	{
		path: pagesEdu.estudiantes.subMenu.vistaGeneral.path,
		element: (
			<ProtectedRoute role='USER'>
				<USERS_PAGE.CONTENIDO_ESTUDIANTES showPendientesPago={false} />
			</ProtectedRoute>
		),
	},
	{
		path: pagesEdu.estudiantes.subMenu.estudiantesPagoPendiente.path,
		element: (
			<ProtectedRoute role='USER'>
				<USERS_PAGE.ESTUDIANTES_PENDIENTES_PAGO />
			</ProtectedRoute>
		),
	},
	{
		path: '/estudiantes/:id',
		element: <USERS_PAGE.DETALLE_ESTUDIANTES />,
	},
];

const estudiantesContentRoutes = [
	{
		path: estudiantesMenu.estudiantesListado.subMenu.listadoEstudiantesIndex
			.path,
		element: (
			<ProtectedRoute role='USER'>
				<USERS_PAGE.ESTUDIANTES />
			</ProtectedRoute>
		),
	},
	{
		path: '/detalle-edicion-estudiante/:id',
		element: <USERS_PAGE.DETALLE_ESTUDIANTE />,
	},
	{
		path: estudiantesMenu.estudiantesListado.subMenu.listadoInscripcionesIndex
			.path,
		element: (
			<ProtectedRoute role='USER'>
				<USERS_PAGE.INSCRIPCIONES />
			</ProtectedRoute>
		),
	},
];

const catalogosMenu = [
	{
		path: catalogos.catalogos.subMenu.catalogoCobros.path,
		element: (
			<ProtectedRoute role={'USER'}>
				<CATALOGOS_PAGE.CATALOGO_COBROS />
			</ProtectedRoute>
		),
	},
	{
		path: catalogos.catalogos.subMenu.catalogoDescuentos.path,
		element: (
			<ProtectedRoute role={'USER'}>
				<CATALOGOS_PAGE.CATALOGO_DESCUENTOS />
			</ProtectedRoute>
		),
	},
	{
		path: catalogos.catalogos.subMenu.catalogoCiclos.path,
		element: (
			<ProtectedRoute role={'USER'}>
				<CATALOGOS_PAGE.CATALOGO_CICLOS />
			</ProtectedRoute>
		),
	},
	{
		path: catalogos.catalogos.subMenu.catalogoGrados.path,
		element: (
			<ProtectedRoute role={'USER'}>
				<CATALOGOS_PAGE.CATALOGO_GRADOS />
			</ProtectedRoute>
		),
	},
	{
		path: catalogos.catalogos.subMenu.catalogoSecciones.path,
		element: (
			<ProtectedRoute role={'USER'}>
				<CATALOGOS_PAGE.CATALOGO_SECCIONES />
			</ProtectedRoute>
		),
	},
];

const depositosMenu = [
  {
    path: depositos.depositos.path,
    element: (
      <ProtectedRoute role={'ADMIN'}>
        <DEPOSITOS_ROUTE.DEPOSITOS />
      </ProtectedRoute>
    ),
  },
];

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},

	/** ************************************************** */

	/**
	 * Page Layout Types
	 */
	{
		path: pageLayoutTypesPagesMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},
];
const contents = [
	...presentation,
	...usuarios,
	...dashboards,
	...catalogosMenu,
	...estudiantesContentRoutes,
  ...depositosMenu,
];

export default contents;
